'use client';
import Script from 'next/script';
import { env } from '../../env';

export default function SkybeamExtraPixel() {
  if (!env.NEXT_PUBLIC_BEACON_URL) {
    return null;
  }
  return (
    <Script id="skybeam-extra-pixel" strategy="afterInteractive">
      {`
        (function(e,n,t){
          e["$$sbFn"]=t;
          e[t]=e[t]||function(){(e[t].q=e[t].q||[]).push(arguments)};
          var c=n.getElementsByTagName("script")[0],
          o=n.createElement("script");
          o.async=true;
          o.src="${env.NEXT_PUBLIC_BEACON_URL}";
          c.parentNode.insertBefore(o,c);
          })(window,document,"sb_beacon");
        // Initial setup and pageview event
        sb_beacon('setup', 'beacon', '3269e439-e438-4296-95aa-df3b18609847');
        sb_beacon('setup', 'user_tracking', true);
        sb_beacon('send', 'pageview');
      `}
    </Script>
  );
}
